import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import "./LatestRecipes.css"
const LatestRecipe = ({ recipes }) => {
  return (
    <div className="accent">
      <section className="section container center-align">
        <h3 className="title">Latest Recipes</h3>
        <hr />
        {recipes.map((recipe, index) => {
          return (
            <Link
              to={`/recipes/${recipe.slug}`}
              key={index}
              className="individual-recipe"
            >
              <article className="project">
                <Image
                  fluid={recipe.image.childImageSharp.fluid}
                  className="project-img"
                />
                <div className="project-info hoverable">
                  <span className="project-number">.0{index + 1}</span>
                  <h3>
                    <span>{recipe.title}</span>
                  </h3>
                  <p className="project-desc">{recipe.description}</p>
                </div>
              </article>
            </Link>
          )
        })}
        <Link to="/recipes" className="btn to-recipes">
          View All Recipes
        </Link>
      </section>
    </div>
  )
}
export default LatestRecipe
