import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import LatestRecipes from "../components/LatestRecipes"
import Jumbotron from "../components/Jumbotron"
import Sect1 from "../components/Sect1"
import SEO from "../components/SEO"

export default ({ data }) => {
  const {
    allStrapiRecipes: { nodes: recipes },
    file: {
      childImageSharp: { fluid },
    },
  } = data
  return (
    <Layout>
      <SEO title="Home" description="Our home page" />
      <Hero
        height="80vh"
        title="fuel bar 15"
        desc="health is wealth"
        fluid={fluid}
      />
      <Jumbotron />
      <LatestRecipes recipes={recipes} />
      <Sect1 />
    </Layout>
  )
}
export const query = graphql`
  {
    allStrapiRecipes(limit: 2, sort: { fields: id, order: DESC }) {
      nodes {
        description
        id
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slug
        title
      }
    }
    file(relativePath: { eq: "hero.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
