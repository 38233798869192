import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import "./Sect1.css"
const query = graphql`
  {
    file(relativePath: { eq: "aux1.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Sect1 = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  return (
    <section>
      <div className="sect1">
        <div className="teal">
          <BackgroundImage
            fluid={fluid}
            className="masthead full-height"
          ></BackgroundImage>
        </div>
        <div className="info-sect1">
          <div className="container center-align sect-container">
            <h3 className="title">Give Your Body The Fuel It Needs</h3>
            <hr />
            <p className="sect-alt">
              If you want to push your body to the limit. You better be giving
              it the right fuel for the job. Great food leads to great
              preformance. It's time to get at it!
            </p>
            <Link
              to="/about"
              className="waves-effect waves-light btn-large brown darken-1 hoverable"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sect1
