import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import "./Jumbotron.css"

const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(filter: { relativeDirectory: { eq: "jumbo" } }) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div className="container center-align section">
      <h3 className="title">Optimize Your Health</h3>
      <hr />
      <p>
        Using clean, all natural ingredients I will show you how to optimize
        your health. If you want to perform like an athlete, you need to eat
        like an athlete too. Nutrition is the key to wellness, recovery, and
        life.
      </p>
      <div className="row image-row">
        {data.images.nodes.map((image, index) => (
          <div className="col s12 m4" key={index}>
            <Image fluid={image.childImageSharp.fluid} key={image.id} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Jumbotron
